
import { Component, Mixins, Watch, Vue } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import SelectSeat from "@/components/SelectSeat/SelectSeat.vue";
import wx from "weixin-js-sdk";

@Component({
  components: { SelectSeat },
})
export default class ExternalAccessSelectSeatPage extends Mixins(Mixin) {
  mounted(): void {
    // 第一时间重置历史数据
    this.SelectSeatModule.resetAllData();
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.refreshData();
    }
  }

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.refreshData();
    }
  }

  refreshData(): void {
    let query = this.$route.query;
    if (query) {
      let surplus = Number(query.surplus);
      if (!isNaN(surplus)) {
        this.SelectSeatModule.SET_surplus(surplus);
      }
    }
    (this.$refs["external-access-select-seat"] as SelectSeat).refresh();
  }

  /**
   * 锁座
   */
  submit(): void {
    this.SelectSeatModule.SET_submitLoading(false);
    switch (this.ChannelModule.channel) {
      case "plat_ios": // IOS
        break;
      case "plat_android": // Android
        if (Vue.prototype.$AppSource == "harmonyOS") {
          this.jsBridge(
            "goConfirmOrder",
            `theaterId/${this.TheaterModule.theaterId},uuid/${this.DeleteLockSeatModule.orderUuid}`
          );
          break;
        } else {
          break;
        }
      case "plat_wechat_miniapp": // 小程序
        wx.miniProgram.navigateTo({
          url:
            "/pagesIndex/lockSeat/lockSeat?sectionId=" +
            this.SelectSeatModule.sectionId +
            "&theaterId=" +
            this.TheaterModule.theaterId +
            "&seatList=" +
            this.SelectSeatModule.selectedSeatIds.join(),
        });
        break;
    }
  }
}
